import { template as template_0690fa9288434a04becda0ed6e7f58d3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0690fa9288434a04becda0ed6e7f58d3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
