import { template as template_03cc0a88e2f44b34a70631efb327ca23 } from "@ember/template-compiler";
const FKText = template_03cc0a88e2f44b34a70631efb327ca23(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
