import { template as template_a33319a459944091865465111d2a4bef } from "@ember/template-compiler";
const FKLabel = template_a33319a459944091865465111d2a4bef(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
